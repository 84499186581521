<template>
	<section class="container">
		<div>
			<h1><span class="badge badge-success">Aktivt aftaler</span></h1>
		</div>

		<template v-for="(item, index) in employeePolicyWithCompany">
			<div class="row" :key="index">
				<div class="col-2">
					<img src="@/assets/images/logos/placeholder.jpg" alt="" />
				</div>
				<div class="col-4">
					<h4 class="mt-2 mb-0">
						<b>{{ item.company.companyInfo.name }}</b>
					</h4>
					<p class="mb-1">{{ item.company.companyInfo.cvr }}</p>
					<p>{{ item.company.companyInfo.address.address }} {{ item.company.companyInfo.address.city }} {{ item.company.companyInfo.address.zip }}</p>
					<p>
						<span class="badge badge-info"
							><h6>Ansat d. {{ getEmployementDate(item) }}</h6></span
						>
					</p>
				</div>
				<div class="col-auto my-auto ml-auto">
					<router-link
						:to="{
							name: 'CompanyEmployeeInfo',
							params: { companyId: item.company.id, employeeId: $route.params.id, policyId: item.policy.id },
						}"
					>
						<b-button class="mb-3"> Gå til aftale </b-button>
					</router-link>
				</div>
			</div>
		</template>

		<template v-if="employeePolicyWithCompany.length == 0"> Brugeren har ikke nogen aftaler </template>
	</section>
</template>

<script>
export default {
	name: 'Agreements',
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		getCompanyFromPolicy(policy) {
			return this.userCompanies?.find((company) => company.id === policy?.companyId)
		},
		getResignationDate(item) {
			var date = item.policy?.employeeCondition?.resignationDate
			return date ? new Date(date).toLocaleDateString('da-dk') : ''
		},
		getEmployementDate(item) {
			var date = item.policy?.employeeCondition?.employmentDate

			return date ? new Date(date).toLocaleDateString('da-dk') : ''
		},
	},
	computed: {
		userCompanies() {
			return this.$store.getters['userVuex/userCompanies']
		},
		userActiveEmployeePolicies() {
			// Active
			return this.$store.getters['userVuex/userEmployeePolicies']?.filter((item) => item.isActive)
		},
		employeePolicyWithCompany() {
			var arr = this.userActiveEmployeePolicies?.map((item) => {
				return {
					policy: item,
					company: this.getCompanyFromPolicy(item),
				}
			})
			return arr
		},
	},
}
</script>
